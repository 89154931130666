<script setup lang="ts">
import {NuxtLink} from "#components";
import {useElementor} from "../../../../../composables/Redmonkey/index";

const {isExternalLink} = useElementor();

const props = defineProps({
  element: Object
})

const attrs = {
  class: 'elementor-button elementor-button-link elementor-size-sm'
};

if(props.element?.settings?.button_css_id) {
  attrs['id'] = props.element.settings.button_css_id;
}

const linkComponent = isExternalLink(props?.element) ? 'a' : NuxtLink;

if(isExternalLink(props?.element)) {
  attrs['href'] = props?.element?.settings?.link?.url;
}else {
  attrs['to'] = props?.element?.settings?.link?.url;
}
</script>

<template>
  <div class="elementor-button-wrapper">
    <component :is="linkComponent" v-bind="attrs">
          <span class="elementor-button-content-wrapper">
									<span class="elementor-button-text">{{ element.settings.text }}</span>
					</span>
    </component>
  </div>
</template>
